<script lang="ts" setup>
const value = ref<string[]>([])

function handleComplete(e: string[]) {
  alert(e.join(''))
}
</script>

<template>
  <PinInputRoot
    id="pin-input"
    v-model="value"
    placeholder="○"
    class="mt-1 flex items-center justify-around gap-1"
    type="number"
    otp
    @complete="handleComplete"
  >
    <PinInputInput
      v-for="(id, index) in 6"
      :key="id"
      :index="index"
      class="text-primary-700 placeholder:text-primary-300 h-10 w-10 rounded bg-white/40 text-center shadow-lg focus:outline focus:outline-2 focus:outline-offset-2 focus:outline-white"
    />
  </PinInputRoot>
</template>

<style></style>
